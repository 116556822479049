.gameOver{
    position: absolute;
    z-index:555;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
    top: 0;
    left: 0;
    color: #ccc;
    font-size: 5rem;
    flex-flow: column nowrap;
    gap: 50px;
}
.newGame{
    font-size: 1.5rem;
    padding: 20px 50px;
}   