.loadingWindow{
    height: 100%;
    width: 100%;
    border: solid red 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 100px;
    
}
.gameBoard{
    display: flex;
    flex-flow: column nowrap;
    gap: 1px;
}
.gameBoardLine{
    display: flex;
    flex-flow: row nowrap;
    gap: 1px;
}
.square{
    width: 30px;
    height: 30px;
    border: solid black 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 25px;
    }
}
.hiddenSquare{
    width: 30px;
    height: 30px;
    border: solid black 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: grey;
    img{
        width: 25px;
    }
}  
.flaggedSquare{
    width: 30px;
    height: 30px;
    border: solid black 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../../public/flag.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
