.controlsContainer{
    width: 380px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    gap: 5px;
    font-family: sans-serif;
    .controlInnerContainer{
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        span{
            width: 160px;
            font-size: 1.5rem;
        }
        .inactive{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 30px;
            background-color: #fff;
            border: solid 1px #ccc;
            border-radius: 5px;
            color: #aaa;
            &:hover{
                border: solid 2px #222;
                color: #222;
                font-weight:bold;
            }
        }
        .active{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 30px;
            border: solid 2px #222; 
            color: #222;
            font-weight:bold;
            border-radius: 5px;
        }
        
    }
    
}